import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { RightArrowIcon, Logo } from 'components/icons';
import { useOptions } from 'contexts/options-context';
import { useAppState } from 'contexts/app-state-context';
import useOnlineStatus from 'hooks/use-online-status';
import ImageUploadButton from 'components/image-upload-button/image-upload-button';
import styles from './header.module.scss';

const Header = () => {
  const isConnected = useOnlineStatus();
  const { updateOptions } = useOptions();

  const inputRef = useRef();
  const [inputVal, setInputVal] = useState('');
  const updateInputVal = (e) => {
    setInputVal(e.target.value);
  };
  const [lastInputVal, setLastInputVal] = useState('');

  const { isLoading } = useAppState().appStates;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLastInputVal(inputVal);
    const newUrl = `${inputVal}${
      inputVal.includes('?') ? '&' : '?'
    }${new Date().getTime()}`;
    updateOptions({ searchURL: newUrl });
    // analytics.logEvent('screenshot_from_url');
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <header id={styles.header}>
      <article className={styles.logo}>
        <Logo className={styles.logoIcon} />
        screenshotr
      </article>
      <Tippy
        content="You are not connected to the internet, please reconnect or upload an image"
        placement="bottom"
        maxWidth={200}
        hideOnClick={false}
        disabled={isConnected}
      >
        <form onSubmit={handleSubmit} className={styles.searchForm}>
          <input
            className={styles.addressInput}
            placeholder="Enter web address..."
            value={inputVal}
            ref={inputRef}
            onChange={updateInputVal}
            type="search"
            disabled={!isConnected}
          />
          <button
            className={classNames('button', styles.goButton)}
            type="submit"
            disabled={
              !inputVal ||
              !isConnected ||
              (isLoading && lastInputVal === inputVal)
            }
          >
            <span>GO</span>
            <RightArrowIcon className={styles.rightArrowIcon} />
          </button>
        </form>
      </Tippy>
      <ImageUploadButton />
    </header>
  );
};

export default Header;
