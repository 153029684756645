import Head from 'next/head';
import classNames from 'classnames';
import Header from 'components/header/header';
import Sidebar from 'components/sidebar/sidebar';
import AppBody from 'components/app-body/app-body';
import BrowserWindow from 'components/browser-window/browser-window';
import MobileWindow from 'components/mobile-window/mobile-window';
import { useUpload } from 'contexts/upload-context';
import { useAppState } from 'contexts/app-state-context';
import styles from './index.module.scss';

const TransparentBackground = ({ isActive }) => (
  <div
    className={classNames(
      styles.transparentBackground,
      isActive ? styles.active : styles.inactive
    )}
  />
);

const MobileOverlay = () => (
  <div className={styles.mobileOverlay}>
    <h1>This app does not work on mobile, sorry about that!</h1>
    <p>
      Due to the limitations of the technology used to make screenshotr, we
      can&apos;t yet create images on smaller screensizes. We also do not plan
      to add this feature any time soon, so please visit this website on a full
      sized browser window!
    </p>
  </div>
);

const AppBodyWrapper = ({ children }) => (
  <section className={styles.appBodyWrapper}>{children}</section>
);

const App = () => {
  const { isDragActive = false } = useUpload();
  const { isDesktop = true } = useAppState().appStates;

  return (
    <div className={styles.app}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>screenshotr | Web browser mockup creation tool</title>
      </Head>
      <TransparentBackground isActive={isDragActive} />
      <Header />
      <AppBodyWrapper>
        <Sidebar />
        <AppBody>{isDesktop ? <BrowserWindow /> : <MobileWindow />}</AppBody>
      </AppBodyWrapper>
      <MobileOverlay />
    </div>
  );
};

export default App;
