import classNames from 'classnames';
import styles from './browser-controls.module.scss';

const BrowserControls = ({
  browserStyle = '',
  visible = true,
  darkLight = '',
}) => (
  <section
    className={classNames(
      styles.browserControls,
      styles[browserStyle],
      styles[darkLight],
      visible ? styles.visible : styles.hidden
    )}
  >
    <article className={classNames(styles.control, styles.control1)} />
    <article className={classNames(styles.control, styles.control2)} />
    <article className={classNames(styles.control, styles.control3)} />
  </section>
);

export default BrowserControls;
