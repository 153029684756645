import classNames from 'classnames';
import { SketchPicker } from 'react-color';
import { EyeDropperIcon } from 'components/icons';
import ColorPaletteButtonRow from 'components/button-row/color-palette-button-row';
import useRendered from 'hooks/use-rendered';
import styles from './background-picker.module.scss';

const BackgroundColorPicker = ({
  handleChange = () => {},
  background = {},
  isEyeDropperDisabled = true,
  isEyeDropperActive = () => {},
  setEyeDropperActive = () => {},
  palette,
}) => {
  const rendered = useRendered();

  return (
    <div>
      {!!palette && (
        <article className="input-container">
          <h3 className="label">Color Palette</h3>
          <ColorPaletteButtonRow
            palette={palette}
            handleChange={handleChange}
          />
        </article>
      )}

      <article className="input-container">
        <button
          type="button"
          disabled={isEyeDropperDisabled}
          className={classNames('button', styles.eyeDropperButton)}
          onClick={() => setEyeDropperActive(!isEyeDropperActive)}
        >
          <EyeDropperIcon className={styles.eyeDropperIcon} />
          {isEyeDropperActive ? (
            <span>Done</span>
          ) : (
            <span>Pick Color from Image</span>
          )}
        </button>
      </article>
      {rendered && (
        <SketchPicker
          disableAlpha
          width={262}
          color={background.backgroundColor}
          onChange={(newColor) =>
            handleChange({ backgroundColor: newColor.hex })
          }
          presetColors={[
            'transparent',
            '#1abc9c',
            '#2ecc71',
            '#3498db',
            '#9b59b6',
            '#34495e',
            '#f1c40f',
            '#e67e22',
            '#e74c3c',
            '#95a5a6',
            '#ffffff',
            '#16a085',
            '#27ae60',
            '#2980b9',
            '#8e44ad',
            '#2c3e50',
            '#f39c12',
            '#d35400',
            '#c0392b',
            '#7f8c8d',
          ]}
        />
      )}
    </div>
  );
};

export default BackgroundColorPicker;
