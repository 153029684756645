/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Checkboard from 'components/checkboard/checkboard';
import Tippy from '@tippyjs/react';
import styles from './background-picker.module.scss';

const BackgroundPickerButton = ({
  background = {},
  openBackgroundPicker = () => {},
}) => (
  <div className={styles.swatchContainer}>
    <Tippy content="Select a Background" placement="bottom">
      <div className={styles.swatch} onClick={openBackgroundPicker}>
        <Checkboard />
        <div className={styles.color} style={background} />
      </div>
    </Tippy>
  </div>
);

export default BackgroundPickerButton;
