import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import styles from './row-input.module.scss';

const RowInput = ({
  label = '',
  name = '',
  options = [],
  value = '',
  onChange = () => {},
  vertical = false,
  containerClassName = '',
  containerStyle = {},
  inputClassName = '',
  inputOptionClassName = '',
  inputOptionSelectedClassName = '',
  inputStyle = {},
}) => (
  <article
    className={classNames(
      'input-container',
      styles.rowInputContainer,
      containerClassName
    )}
    style={containerStyle}
  >
    {label && (
      <label
        id={`${name}_label`}
        className={styles.rowInputLabel}
        htmlFor={name}
      >
        {label}
      </label>
    )}

    <div
      aria-labelledby={`${name}_label`}
      role="radiogroup"
      id={name}
      className={classNames(
        styles.rowInput,
        vertical ? styles.vertical : '',
        inputClassName
      )}
      style={inputStyle}
    >
      {options.map((option) => {
        const isSelected =
          typeof value === 'string' || typeof value === 'number'
            ? option.value === value
            : option.value === value.value;

        return (
          <Tippy
            content={option.tooltip}
            disabled={!option.tooltip}
            maxWidth={200}
            key={option.value}
          >
            <label
              htmlFor={`${name}_${option.value}`}
              className={classNames(
                styles.rowInputOption,
                inputOptionClassName,
                isSelected ? styles.selected : '',
                isSelected ? inputOptionSelectedClassName : ''
              )}
            >
              <input
                type="radio"
                name={name}
                id={`${name}_${option.value}`}
                value={option.value}
                checked={isSelected}
                onChange={() => onChange(option)}
              />
              {option.label}
            </label>
          </Tippy>
        );
      })}
    </div>
  </article>
);

export default RowInput;
