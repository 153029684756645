import { useRef } from 'react';
import classNames from 'classnames';
import useComponentSize from 'hooks/use-component-size';
import { LoaderFill } from 'components/loader/loader';
import { useOptions } from 'contexts/options-context';
import { useOutput } from 'contexts/output-context';
import { useAppState } from 'contexts/app-state-context';
import { useUpload } from 'contexts/upload-context';
import { getColorFromElement } from 'util/color';
import { UploadIcon } from 'components/icons';
import BrowserHeader from './browser-header';
import styles from './browser-window.module.scss';

const BrowserImage = ({ image, cursor = 'default', onClick = () => {} }) => (
  <img
    className={styles.screenshotImage}
    src={image}
    alt="Screenshot"
    style={{ cursor }}
    onClick={onClick}
  />
);

const BrowserPlaceholder = ({ height, children }) => (
  <article className={styles.webFramePlaceholder} style={{ height }}>
    {children}
  </article>
);

const WelcomeMessage = () => (
  <div className={styles.content}>
    <h1>
      Welcome to <strong>screenshotr</strong>
    </h1>
    <p>Create professional looking web browser mockups in a snap</p>
    <p>Just enter a URL at the top and click GO!</p>
  </div>
);

const UploadMessage = ({ isActive = false }) => (
  <div
    className={classNames(styles.webFramePlaceholder, styles.upload, {
      [styles.active]: isActive,
    })}
  >
    <div className={styles.uploadContent}>
      <UploadIcon className={styles.uploadIcon} />
      <span className={styles.uploadDescription}>Drop a screenshot here!</span>
    </div>
  </div>
);

const BrowserWindow = () => {
  const { options, updateOptions } = useOptions();
  const {
    desktopSubjectWidth,
    controlScale,
    shadow: shadowStyle,
    style: { value: browserStyle },
    darkLight,
  } = options;

  const { isEyeDropperActive, isLoading } = useAppState().appStates;

  const { screenshot } = useOutput().output;

  const browserWindowRef = useRef();
  const browserWidth = useComponentSize(browserWindowRef).width;
  const placeholderHeight = browserWidth / (16 / 9);
  const isBrowserSkinny = browserWidth < 500;

  const handleImageClick = async (e) => {
    if (isEyeDropperActive) {
      const backgroundColor = await getColorFromElement(e);
      updateOptions({
        background: { backgroundColor },
      });
    }
  };

  const { isDragActive } = useUpload();

  return (
    <article
      ref={browserWindowRef}
      className={classNames(
        styles.browserWindow,
        styles[browserStyle],
        styles[shadowStyle],
        styles[darkLight],
        {
          [styles.skinny]: isBrowserSkinny,
        }
      )}
      style={{
        fontSize: controlScale * 16,
        width: desktopSubjectWidth,
        minWidth: desktopSubjectWidth,
      }}
    >
      <BrowserHeader isBrowserSkinny={isBrowserSkinny} />
      {screenshot && !isLoading ? (
        <BrowserImage
          image={screenshot}
          onClick={handleImageClick}
          cursor={isEyeDropperActive ? 'crosshair' : 'inherit'}
        />
      ) : (
        <BrowserPlaceholder height={placeholderHeight}>
          {isLoading ? <LoaderFill color="#333" /> : <WelcomeMessage />}
        </BrowserPlaceholder>
      )}
      <UploadMessage isActive={isDragActive} />
    </article>
  );
};

export default BrowserWindow;
