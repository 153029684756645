import IconButton from './icon-button';
import styles from './button-row.module.scss';

const ColorPaletteButtonRow = ({ handleChange = () => {}, palette = [] }) => {
  return (
    <section className={styles.buttonRow}>
      {palette.map((color) => (
        <IconButton
          key={`palette-color-${color}`}
          onClick={() => handleChange({ backgroundColor: color })}
          tooltipContent={
            <div className={styles.tooltipContent}>
              <div>Set Background</div>
              <div>{color.toUpperCase()}</div>
            </div>
          }
          color={color}
        />
      ))}
    </section>
  );
};

export default ColorPaletteButtonRow;
