import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { CenterMapIcon } from 'components/icons';
import styles from './app-body.module.scss';

const ZoomPanControl = ({ zoomPanState, setZoomPanState }) => {
  const zoom = Math.round(zoomPanState?.scale * 100 || 1);

  return (
    <div
      className={classNames(
        styles.zoomPanControl,
        styles.zoomPanControl_labelContainer
      )}
    >
      <div className={classNames(styles.zoomPanControl_label)}>
        Zoom: <strong>{zoom}%</strong>
      </div>
      <Tippy content="Reset" placement="bottom">
        <button
          type="button"
          className={styles.zoomPanControl_reset}
          onClick={() =>
            setZoomPanState({
              scale: 1,
              translation: {
                x: 0,
                y: 0,
              },
            })
          }
        >
          <CenterMapIcon />
        </button>
      </Tippy>
    </div>
  );
};

export default ZoomPanControl;
