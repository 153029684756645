/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { BACKGROUND_TYPES } from 'lib/constants';
import BackgroundPickerButton from './background-picker-button';
import BackgroundPickerMenu from './background-picker-menu';
import BackgroundColorPicker from './background-color-picker';
import BackgroundGradientPicker from './background-gradient-picker';
import BackgroundImagePicker from './background-image-picker';

const BackgroundPicker = ({
  onChange = () => {},
  background = {},
  isEyeDropperDisabled = true,
  isEyeDropperActive = false,
  isBackgroundPickerOpen = false,
  setEyeDropperActive = () => {},
  setBackgroundPickerOpen = () => {},
  palette,
}) => {
  const [currentBackgroundType, setBackgroundType] = useState(
    () => BACKGROUND_TYPES[0]
  );

  const handleChange = (newColor) => {
    onChange(newColor);
  };

  const openBackgroundPicker = () => {
    setBackgroundPickerOpen(true);
  };

  const closeBackgroundPicker = () => {
    setBackgroundPickerOpen(false);
    setEyeDropperActive(false);
  };

  return (
    <>
      <BackgroundPickerButton
        background={background}
        openBackgroundPicker={openBackgroundPicker}
      />
      <BackgroundPickerMenu
        handleChange={handleChange}
        background={background}
        isBackgroundPickerOpen={isBackgroundPickerOpen}
        setEyeDropperActive={setEyeDropperActive}
        closeBackgroundPicker={closeBackgroundPicker}
        currentBackgroundType={currentBackgroundType}
        setBackgroundType={setBackgroundType}
      >
        {currentBackgroundType.value === 'solid' && (
          <BackgroundColorPicker
            handleChange={handleChange}
            background={background}
            isEyeDropperDisabled={isEyeDropperDisabled}
            isEyeDropperActive={isEyeDropperActive}
            setEyeDropperActive={setEyeDropperActive}
            palette={palette}
          />
        )}

        {currentBackgroundType.value === 'gradient' && (
          <BackgroundGradientPicker handleChange={handleChange} />
        )}

        {currentBackgroundType.value === 'image' && (
          <BackgroundImagePicker handleChange={handleChange} />
        )}
      </BackgroundPickerMenu>
    </>
  );
};

export default BackgroundPicker;
