import classNames from 'classnames';
import BrowserControls from 'components/browser-controls/browser-controls';
import { useOptions } from 'contexts/options-context';
import { useOutput } from 'contexts/output-context';
import styles from './browser-window.module.scss';

const HeaderContainer = ({ children }) => (
  <section className={styles.headerBar}>{children}</section>
);

const AddressBar = ({ children = '', addressType = 'addressFull' }) => (
  <section className={classNames(styles.addressBar, styles[addressType])}>
    {children}
  </section>
);

const Favicon = ({ icon }) => (
  <img className={styles.favicon} src={icon} alt="favicon" />
);

const Address = ({ children, addressType }) => (
  <span className={classNames(styles.address, styles[addressType])}>
    {children}
  </span>
);

const BrowserHeader = ({ isBrowserSkinny }) => {
  const {
    style: { value: browserStyle },
    darkLight,
    address: addressType,
  } = useOptions().options;

  const {
    output: { favicon },
    cleanURL,
  } = useOutput();

  const areControlsOnLeft = !browserStyle.toLowerCase().includes('windows');

  return (
    <HeaderContainer>
      {(areControlsOnLeft || !isBrowserSkinny) && (
        <BrowserControls
          browserStyle={browserStyle}
          darkLight={darkLight}
          visible={areControlsOnLeft}
        />
      )}
      <AddressBar addressType={addressType}>
        {favicon && addressType === 'addressFull' && <Favicon icon={favicon} />}
        <Address addressType={addressType}>{cleanURL}</Address>
      </AddressBar>
      {(!areControlsOnLeft || !isBrowserSkinny) && (
        <BrowserControls
          browserStyle={browserStyle}
          darkLight={darkLight}
          visible={!areControlsOnLeft}
        />
      )}
    </HeaderContainer>
  );
};

export default BrowserHeader;
