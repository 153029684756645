import classNames from 'classnames';
import BackgroundPicker from 'components/background-picker/background-picker';
import RangeInput from 'components/range-input/range-input';
import RowInput from 'components/row-input/row-input';
import SelectInput from 'components/select-input/select-input';
import PresetButtonRow from 'components/button-row/preset-button-row';
import Loader from 'components/loader/loader';
import { DownloadIcon } from 'components/icons';
import { useOptions } from 'contexts/options-context';
import { useOutput } from 'contexts/output-context';
import { useAppState } from 'contexts/app-state-context';
import {
  RESOLUTION_OPTIONS,
  STYLE_OPTIONS,
  SHADOW_OPTIONS,
  DARK_LIGHT_OPTIONS,
  ADDRESS_BAR_OPTIONS,
  DEVICE_TYPES,
  MOBILE_DEVICES,
  FILE_TYPES,
  OUTPUT_SCALES,
} from 'lib/constants';
import styles from './sidebar.module.scss';

const Sidebar = () => {
  const { options, updateOptions } = useOptions();
  const {
    style,
    resolution,
    background,
    controlScale,
    shadow,
    darkLight,
    address,
    minOutputWidth,
    maxOutputWidth,
    minOutputHeight,
    maxOutputHeight,
    mobileDevice,
    deviceColor,
    desktopOutputWidth,
    desktopOutputHeight,
    desktopSubjectWidth,
    mobileOutputWidth,
    mobileOutputHeight,
    mobileSubjectWidth,
    fileType,
    outputQuality,
    outputScale,
  } = options;

  const hasDarkLightOption =
    style?.value?.toLowerCase()?.includes('apple') ||
    style?.value?.toLowerCase()?.includes('windows10');

  const {
    output,
    handleDownloadClick,
    filenameInputRef,
    isDownloadLoading,
  } = useOutput();
  const { screenshot, isUpload, palette } = output;

  const { appStates, updateAppStates } = useAppState();
  const {
    isBackgroundPickerOpen,
    isEyeDropperActive,
    deviceType,
    isDesktop,
    isLoading,
  } = appStates;

  const { colorOptions } = mobileDevice.value;
  const deviceHasColorOptions = !!colorOptions;

  const outputWidth = isDesktop ? desktopOutputWidth : mobileOutputWidth;
  const outputHeight = isDesktop ? desktopOutputHeight : mobileOutputHeight;
  const subjectWidth = isDesktop ? desktopSubjectWidth : mobileSubjectWidth;

  return (
    <article id={styles.sidebar}>
      <RowInput
        name="screenshot-type"
        containerClassName={styles.topBarRowInputContainer}
        inputClassName={styles.topBarRowInput}
        inputOptionSelectedClassName={styles.topBarRowInputOptionSelected}
        options={DEVICE_TYPES}
        value={deviceType}
        onChange={(newDeviceType) => {
          updateAppStates({ deviceType: newDeviceType });
          updateOptions({
            resolution:
              newDeviceType.value === 'desktop'
                ? RESOLUTION_OPTIONS[3]
                : {
                    value: mobileDevice.value.physicalResolution,
                  },
          });
        }}
      />

      <div className={styles.sidebarContent}>
        <h2>Style</h2>

        {isDesktop ? (
          <>
            <article className="input-container">
              <label htmlFor="browser-style">Browser Style</label>
              <SelectInput
                id="browser-style"
                options={STYLE_OPTIONS}
                onChange={(newStyle) => {
                  updateOptions({ style: newStyle });
                }}
                value={style}
              />
            </article>

            {hasDarkLightOption && (
              <RowInput
                name="dark-light"
                containerStyle={{ marginTop: '1rem' }}
                options={DARK_LIGHT_OPTIONS}
                value={darkLight}
                onChange={(option) =>
                  updateOptions({ darkLight: option.value })
                }
              />
            )}
          </>
        ) : (
          <>
            <article className="input-container">
              <label htmlFor="mobile-device">Device</label>
              <SelectInput
                id="mobile-device"
                options={MOBILE_DEVICES}
                onChange={(newDevice) => {
                  updateOptions({
                    mobileDevice: newDevice,
                    resolution: { value: newDevice.value.physicalResolution },
                    deviceColor: newDevice.value?.colorOptions?.[0] || {},
                  });
                }}
                value={mobileDevice}
              />
            </article>

            {deviceHasColorOptions && (
              <article className="input-container">
                <label htmlFor="device-color">Device Color</label>
                <SelectInput
                  id="device-color"
                  options={colorOptions}
                  onChange={(newColor) =>
                    updateOptions({ deviceColor: newColor })
                  }
                  value={deviceColor}
                />
              </article>
            )}
          </>
        )}

        <article className="input-container">
          <label htmlFor="background-color">Background</label>
          <BackgroundPicker
            id="background-color"
            onChange={(newBackground) =>
              updateOptions({ background: newBackground })
            }
            background={background}
            isEyeDropperDisabled={!screenshot}
            isEyeDropperActive={isEyeDropperActive}
            setEyeDropperActive={(newEyeDropperActive) => {
              updateAppStates({ isEyeDropperActive: newEyeDropperActive });
            }}
            isBackgroundPickerOpen={isBackgroundPickerOpen}
            setBackgroundPickerOpen={(newBackgroundPickerOpen) => {
              updateAppStates({
                isBackgroundPickerOpen: newBackgroundPickerOpen,
              });
            }}
            palette={palette}
          />
        </article>

        <RowInput
          label="Shadow"
          name="shadow"
          options={SHADOW_OPTIONS}
          value={shadow}
          onChange={(option) => updateOptions({ shadow: option.value })}
        />

        {isDesktop && (
          <RowInput
            label="Address Bar"
            name="address-bar"
            options={ADDRESS_BAR_OPTIONS}
            value={address}
            onChange={(option) => updateOptions({ address: option.value })}
          />
        )}

        <h2>
          Sizing{' '}
          <span>
            {outputWidth} × {outputHeight}
          </span>
        </h2>

        <article className="input-container">
          <h3 className="label">Presets</h3>
          <PresetButtonRow />
        </article>

        {!isUpload && isDesktop && (
          <article className="input-container">
            <label htmlFor="screenshot-resolution">Screenshot Resolution</label>
            <SelectInput
              id="screenshot-resolution"
              options={RESOLUTION_OPTIONS}
              onChange={(newResolution) => {
                updateOptions({ resolution: newResolution });
              }}
              value={resolution}
            />
          </article>
        )}

        <RangeInput
          containerClassName="input-container"
          label="Canvas Width"
          id="output-width"
          value={outputWidth}
          min={minOutputWidth}
          max={maxOutputWidth}
          onChange={(val) =>
            updateOptions(
              isDesktop
                ? { desktopOutputWidth: val }
                : { mobileOutputWidth: val }
            )
          }
          unit="px"
          editable
        />

        <RangeInput
          containerClassName="input-container"
          label="Canvas Height"
          id="output-height"
          value={outputHeight}
          min={minOutputHeight}
          max={maxOutputHeight}
          onChange={(val) =>
            updateOptions(
              isDesktop
                ? { desktopOutputHeight: val }
                : { mobileOutputHeight: val }
            )
          }
          unit="px"
          editable
        />

        <RangeInput
          containerClassName="input-container"
          label={isDesktop ? 'Browser Width' : 'Device Width'}
          id="subject-width"
          value={subjectWidth}
          max={maxOutputWidth}
          onChange={(val) =>
            updateOptions(
              isDesktop
                ? { desktopSubjectWidth: val }
                : { mobileSubjectWidth: val }
            )
          }
          unit="px"
          editable
        />

        {isDesktop && (
          <RangeInput
            containerClassName="input-container"
            label="Browser Scale"
            id="control-scale"
            value={controlScale}
            min={0.5}
            max={4}
            step={0.25}
            onChange={(val) => updateOptions({ controlScale: val })}
            unit={<div style={{ fontSize: 22 }}>×</div>}
            editable
          />
        )}

        <h2>Download Options</h2>

        <article className="input-container">
          <label htmlFor={styles.fileName}>Filename</label>
          <input
            id={styles.fileName}
            ref={filenameInputRef}
            type="text"
            placeholder="Enter a custom filename..."
          />
        </article>

        <RowInput
          label="File Type"
          name="file-type"
          options={FILE_TYPES}
          value={fileType}
          onChange={(option) => updateOptions({ fileType: option })}
        />

        {fileType.value === 'jpg' && (
          <RangeInput
            containerClassName="input-container"
            label="Quality"
            id="output-quality"
            value={outputQuality}
            min={0}
            max={100}
            onChange={(val) => updateOptions({ outputQuality: val })}
            unit="%"
            editable
          />
        )}

        <RowInput
          label="Scale"
          name="scale"
          options={OUTPUT_SCALES}
          value={outputScale}
          onChange={(option) => updateOptions({ outputScale: option })}
        />
      </div>
      <button
        className={classNames('button', styles.downloadButton)}
        type="button"
        onClick={handleDownloadClick}
        disabled={isLoading || !screenshot}
      >
        {isDownloadLoading ? (
          <Loader color="#fff" />
        ) : (
          <>
            <span>Download</span>
            <DownloadIcon
              style={{ marginLeft: '0.75rem', marginBottom: '0.25rem' }}
            />
          </>
        )}
      </button>
    </article>
  );
};

export default Sidebar;
