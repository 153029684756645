import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import styles from './button-row.module.scss';

const IconButton = ({ tooltipContent, onClick, icon, color }) => (
  <Tippy content={tooltipContent} placement="bottom">
    <button
      type="button"
      className={classNames('button', styles.iconButton)}
      onClick={onClick}
      style={{ backgroundColor: color }}
    >
      {icon}
    </button>
  </Tippy>
);

export default IconButton;
