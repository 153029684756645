import classNames from 'classnames';
import styles from './loader.module.scss';

const Loader = ({ color = '#333' }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export const BrowserLoader = () => (
  <div className={styles.loaderContainer}>
    <div className={styles.browserLoader}>
      <header>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </header>
    </div>
    <div className={styles.loaderFloor} />
  </div>
);

export const LoaderFill = () => (
  <article className={styles.loaderFill}>
    <BrowserLoader />
    <h2
      style={{
        fontWeight: 400,
        fontSize: '1.625rem',
        marginTop: '.5rem',
      }}
    >
      Please wait while we grab your screenshot
    </h2>
    <h2
      style={{
        fontWeight: 400,
        fontSize: '1.25rem',
        marginTop: '.25rem',
      }}
    >
      (This can take up to 30 seconds)
    </h2>
  </article>
);

export const BasicLoaderFill = ({ color = '#333', className, style = {} }) => (
  <article className={classNames(styles.loaderFill, className)} style={style}>
    <Loader color={color} />
  </article>
);

export default Loader;
