import { useOptions } from 'contexts/options-context';
import { useAppState } from 'contexts/app-state-context';
import {
  AppleIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  ProductHuntIcon,
  YouTubeIcon,
  DribbbleIcon,
} from 'components/icons';
import IconButton from './icon-button';
import styles from './button-row.module.scss';

const PresetButtonRow = () => {
  const {
    updateOptions,
    options: { mobileDevice },
  } = useOptions();

  const { isDesktop } = useAppState().appStates;

  return (
    <section className={styles.buttonRow}>
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.round(980 * mobileAspect);
          updateOptions({
            desktopOutputWidth: 1080,
            desktopOutputHeight: 1080,
            desktopSubjectWidth: 1000,
            mobileOutputWidth: 1080,
            mobileOutputHeight: 1080,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>Instagram Post</div>
            <div>1080 × 1080</div>
          </div>
        }
        icon={<InstagramIcon />}
        color="#8134af"
      />
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.min(
            Math.round(1720 * mobileAspect),
            900
          );
          updateOptions({
            desktopOutputWidth: 1080,
            desktopOutputHeight: 1920,
            desktopSubjectWidth: 1000,
            mobileOutputWidth: 1080,
            mobileOutputHeight: 1920,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>Facebook/Instagram Story</div>
            <div>1080 × 1920</div>
          </div>
        }
        icon={<FacebookIcon />}
        color="#1877F2"
      />
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.round(520 * mobileAspect);
          updateOptions({
            desktopOutputWidth: 1040,
            desktopOutputHeight: 584,
            desktopSubjectWidth: 860,
            mobileOutputWidth: 1040,
            mobileOutputHeight: 584,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>Twitter Post</div>
            <div>1040 × 584</div>
          </div>
        }
        icon={<TwitterIcon />}
        color="#1da1f2"
      />
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.round(680 * mobileAspect);
          updateOptions({
            desktopOutputWidth: 1270,
            desktopOutputHeight: 760,
            desktopSubjectWidth: 1100,
            mobileOutputWidth: 1270,
            mobileOutputHeight: 760,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>Product Hunt Post</div>
            <div>1270 × 760</div>
          </div>
        }
        icon={<ProductHuntIcon />}
        color="#da552f"
      />
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.round(660 * mobileAspect);
          updateOptions({
            desktopOutputWidth: 1280,
            desktopOutputHeight: 720,
            desktopSubjectWidth: 1100,
            mobileOutputWidth: 1280,
            mobileOutputHeight: 720,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>YouTube Thumbnail</div>
            <div>1280 × 720</div>
          </div>
        }
        icon={<YouTubeIcon />}
        color="#ff0000"
      />
      <IconButton
        onClick={() => {
          const {
            width: mobileWidth,
            height: mobileHeight,
          } = mobileDevice.value.cssResolution;
          const mobileAspect = mobileWidth / mobileHeight;
          const mobileSubjectWidth = Math.round(950 * mobileAspect);
          updateOptions({
            desktopOutputWidth: 1600,
            desktopOutputHeight: 1200,
            desktopSubjectWidth: 1300,
            browserScale: 1.25,
            mobileOutputWidth: 1600,
            mobileOutputHeight: 1200,
            mobileSubjectWidth,
          });
        }}
        tooltipContent={
          <div className={styles.tooltipContent}>
            <div>Dribbble Post</div>
            <div>1600 × 1200</div>
          </div>
        }
        icon={<DribbbleIcon />}
        color="#EA4C89"
      />
      {!isDesktop && (
        <>
          <IconButton
            onClick={() => {
              const {
                width: mobileWidth,
                height: mobileHeight,
              } = mobileDevice.value.cssResolution;
              const mobileAspect = mobileWidth / mobileHeight;
              const mobileSubjectWidth = Math.min(
                Math.round(2500 * mobileAspect),
                1160
              );
              updateOptions({
                mobileOutputWidth: 1284,
                mobileOutputHeight: 2778,
                mobileSubjectWidth,
              });
            }}
            tooltipContent={
              <div className={styles.tooltipContent}>
                <div>Apple App Store - 6.5&quot; iPhone</div>
                <div>1284 × 2778</div>
              </div>
            }
            icon={<AppleIcon />}
            color="#555"
          />
          <IconButton
            onClick={() => {
              const {
                width: mobileWidth,
                height: mobileHeight,
              } = mobileDevice.value.cssResolution;
              const mobileAspect = mobileWidth / mobileHeight;
              const mobileSubjectWidth = Math.min(
                Math.round(2050 * mobileAspect),
                1160
              );
              updateOptions({
                mobileOutputWidth: 1242,
                mobileOutputHeight: 2208,
                mobileSubjectWidth,
              });
            }}
            tooltipContent={
              <div className={styles.tooltipContent}>
                <div>Apple App Store - 5.5&quot; iPhone</div>
                <div>1242 × 2208</div>
              </div>
            }
            icon={<AppleIcon />}
            color="#555"
          />
        </>
      )}
    </section>
  );
};

export default PresetButtonRow;
