import { MapInteractionCSS } from 'react-map-interaction';
import { PlusIcon, MinusIcon } from 'components/icons';
import styles from './app-body.module.scss';

const ZoomPanWrapper = ({
  children,
  width,
  height,
  maxWidth,
  maxHeight,
  zoomPanState,
  setZoomPanState,
}) => {
  const { scale = 1 } = zoomPanState;

  const xMax = ((maxWidth - width) / 2) * scale;
  const yMax = ((maxHeight - height) / 2) * scale;
  const xMin = xMax * -1 + (1 - scale) * width;
  const yMin = yMax * -1 + (1 - scale) * height;

  const bounds = { xMin, yMin, xMax, yMax };

  const minScale = Math.max(width / maxWidth, height / maxHeight);

  return (
    <MapInteractionCSS
      controlsClass={styles.zoomPanControl_container}
      btnClass={styles.zoomPanControl}
      minusBtnClass={styles.zoomPanControl_minus}
      plusBtnClass={styles.zoomPanControl_plus}
      plusBtnContents={<PlusIcon />}
      minusBtnContents={<MinusIcon />}
      translationBounds={bounds}
      value={zoomPanState}
      maxScale={1.5}
      minScale={minScale}
      showControls
      onChange={(val) => {
        setZoomPanState(val);
      }}
    >
      {children}
    </MapInteractionCSS>
  );
};

export default ZoomPanWrapper;
