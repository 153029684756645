/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import gradienta from 'lib/gradienta';
import webgradients from 'lib/webgradients';
import styles from './background-picker.module.scss';

const gradients = [
  ...gradienta.map((gradient, index) => ({
    key: `gradienta-${index}`,
    style: gradient,
  })),
  ...Object.keys(webgradients).map((key) => ({
    key: `webgradients-${key}`,
    style: webgradients[key],
  })),
];

const BackgroundGradientPicker = ({ handleChange = () => {} }) => (
  <article className="input-container">
    <div className={styles.gradientPicker}>
      {gradients.map((gradient) => (
        <div
          key={gradient.key}
          className={styles.gradientTile}
          style={gradient.style}
          onClick={() => handleChange(gradient.style)}
        />
      ))}
    </div>
  </article>
);

export default BackgroundGradientPicker;
