/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import Checkboard from 'components/checkboard/checkboard';
import { LeftArrowIcon, ShuffleIcon, MinusIcon } from 'components/icons';
import RowInput from 'components/row-input/row-input';
import { BACKGROUND_TYPES } from 'lib/constants';
import gradienta from 'lib/gradienta';
import webgradients from 'lib/webgradients';
import { getRandomColor } from 'util/color';
import styles from './background-picker.module.scss';

const BackgroundPickerMenu = ({
  background = {},
  isBackgroundPickerOpen = false,
  closeBackgroundPicker = () => {},
  setBackgroundType = () => {},
  currentBackgroundType,
  handleChange = () => {},
  children,
}) => {
  const getRandomBackground = () => {
    const backgroundType =
      BACKGROUND_TYPES[Math.floor(Math.random() * 3)].value;

    if (backgroundType === 'solid') {
      handleChange({ background: getRandomColor() });
    }

    if (backgroundType === 'gradient') {
      const gradientKeys = Object.keys(webgradients);
      const randomKey =
        gradientKeys[Math.floor(Math.random() * gradientKeys.length)];
      const randomGradient = webgradients[randomKey];
      handleChange(randomGradient);
    }

    if (backgroundType === 'stripes') {
      const randomStripes =
        gradienta[Math.floor(Math.random() * gradienta.length)];
      handleChange(randomStripes);
    }
  };

  const removeBackground = () => {
    handleChange({ background: 'transparent' });
  };

  return (
    <div
      className={classNames(
        styles.backgroundPickerMenu,
        isBackgroundPickerOpen ? styles.open : styles.closed
      )}
    >
      <header onClick={closeBackgroundPicker}>
        <LeftArrowIcon />
        <span>Background</span>
      </header>
      <h3 className="label">Current</h3>
      <div className={styles.swatchContainer}>
        <div className={styles.swatch} style={{ cursor: 'default' }}>
          <Checkboard />
          <div className={styles.color} style={background} />
        </div>

        <Tippy content="Random Background">
          <button
            type="button"
            className={classNames('button', styles.swatchContainerButton)}
            onClick={getRandomBackground}
          >
            <ShuffleIcon />
          </button>
        </Tippy>

        <Tippy content="Remove Background">
          <button
            type="button"
            className={classNames('button', styles.swatchContainerButton)}
            onClick={removeBackground}
          >
            <MinusIcon />
          </button>
        </Tippy>
      </div>

      <div className="sidebar-divider" />

      <RowInput
        name=""
        options={BACKGROUND_TYPES}
        containerStyle={{ marginTop: '1rem' }}
        inputStyle={{ marginTop: 0 }}
        value={currentBackgroundType}
        onChange={(option) => setBackgroundType(option)}
        containerClassName={styles.backgroundPickerRowPicker}
      />

      {children}
    </div>
  );
};

export default BackgroundPickerMenu;
