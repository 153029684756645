import { useState, useCallback, useLayoutEffect } from 'react';

const getSize = (el) =>
  el
    ? {
        width: el.offsetWidth,
        height: el.offsetHeight,
      }
    : {
        width: 0,
        height: 0,
      };

const useComponentSize = (ref) => {
  const [componentSize, setComponentSize] = useState(getSize(ref?.current));

  const handleResize = useCallback(() => {
    if (ref.current) {
      setComponentSize(getSize(ref.current));
    }
  }, [ref]);

  useLayoutEffect(() => {
    const el = ref.current;

    if (!el) {
      return null;
    }

    handleResize();

    if (typeof ResizeObserver === 'function') {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(el);

      return () => {
        resizeObserver.disconnect(el);
      };
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, handleResize]);

  return componentSize;
};

export default useComponentSize;
