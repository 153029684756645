import Tippy from '@tippyjs/react';
import { useUpload } from 'contexts/upload-context';
import { ImageUploadIcon } from 'components/icons';
import styles from './image-upload-button.module.scss';

const ImageUploadButton = () => {
  const { handleFiles } = useUpload();
  return (
    <Tippy content="Upload an Image">
      <label
        htmlFor="screenshot-upload-input"
        className={styles.imageUploadButton}
      >
        <input
          id="screenshot-upload-input"
          className="hidden-input"
          type="file"
          accept="image/*"
          onChange={(e) => handleFiles(e.target.files)}
        />
        <ImageUploadIcon className={styles.imageUploadIcon} />
      </label>
    </Tippy>
  );
};

export default ImageUploadButton;
